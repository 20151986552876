import React, { useState, useEffect } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./i18n";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tabs, Tab } from "react-bootstrap";

const App = () => {
  const { t } = useTranslation();
  const [downColor, setDownColor] = useState("green");
  const [downColorOrange, setDownColorOrange] = useState(false);
  const [upColor, setUpColor] = useState("red");
  const [upColorOrange, setUpColorOrange] = useState(false);
  const [downTime, setDownTime] = useState("6:59");
  const [upTime, setUpTime] = useState("18:48");

  useEffect(() => {
    const initialTime = new Date("2023-06-05T16:11:48"); // ve směru dolů je čas 6:58
    const cycleDurationGreen = 6 * 60 + 59;
    const cycleDurationRed = 18 * 60 + 49;
    const cycleDuration = cycleDurationGreen + cycleDurationRed;
    const diff = 26;
    const halfCycle = Math.floor(cycleDuration / 2) - diff;
    let inc = 0;

    const updateTrafficLights = () => {
      const currentTime = new Date();
      // const currentTime = new Date("2023-06-05T16:11:49"); //testovací čas - 6:58 ve směru dolů
      // const currentTime = new Date("2023-06-05T16:18:49"); //testovací čas - 18:47 ve směru dolů
      // const currentTime = new Date("2023-06-05T20:27:00"); //testovací čas - 2:49 ve směru dolů
      // const currentTime = new Date("2023-06-07T10:06:32"); //testovací čas - 1:12 ve směru nahoru
      // const currentTime = new Date("2023-06-07T10:14:43"); //testovací čas - 0:12 ve směru nahoru
      // const currentTime = new Date("2023-06-05T16:22:38"); //
      // const currentTime = new Date("2023-06-23T14:25:00"); //
      // const currentTime = new Date("2023-06-05T16:37:28"); //
      // inc = inc + 1;
      const elapsedTime = Math.floor((currentTime - initialTime) / 1000) + inc;
      // Celková délka cyklu v sekundách
      // const elapsedCycles = Math.floor(elapsedTime / cycleDuration); //počet cyklů od počátku
      // console.log(diff);
      const downRemainingTime = cycleDuration - (elapsedTime % cycleDuration);

      const lengthYellow = 3;

      let downSecondsRemaining = 0;
      if (
        downRemainingTime - cycleDurationRed <= lengthYellow &&
        downRemainingTime - cycleDurationRed > 0
      ) {
        setDownColor("");
        setDownColorOrange(true);
        downSecondsRemaining = downRemainingTime - cycleDurationRed; // zelená dolů
      } else if (downRemainingTime <= lengthYellow && downRemainingTime > 0) {
        setDownColor("red");
        setDownColorOrange(true);
        downSecondsRemaining = downRemainingTime; // červená dolů
      } else if (downRemainingTime - lengthYellow > cycleDurationRed) {
        setDownColorOrange(false);
        downSecondsRemaining = downRemainingTime - cycleDurationRed; // zelená dolů
        setDownColor("green");
      } else {
        setDownColorOrange(false);
        downSecondsRemaining = downRemainingTime; // červená dolů
        setDownColor("red");
      }

      let upRemainingTime = 0;
      if (elapsedTime % cycleDuration < halfCycle + 2 * diff) {
        upRemainingTime =
          cycleDuration - (elapsedTime % cycleDuration) - halfCycle;
      } else {
        upRemainingTime =
          cycleDuration + halfCycle + 2 * diff - (elapsedTime % cycleDuration);
      }

      let upSecondsRemaining = 0;
      if (upRemainingTime <= lengthYellow && upRemainingTime > 0) {
        setUpColor("red");
        setUpColorOrange(true);
        upSecondsRemaining = upRemainingTime; // červená dolů
      } else if (upRemainingTime - lengthYellow > cycleDurationRed) {
        setUpColorOrange(false);
        upSecondsRemaining = upRemainingTime - cycleDurationRed; // zelená dolů
        setUpColor("green");
      } else if (
        upRemainingTime - cycleDurationRed <= lengthYellow &&
        upRemainingTime - cycleDurationRed > 0
      ) {
        setUpColor("");
        setUpColorOrange(true);
        upSecondsRemaining = upRemainingTime - cycleDurationRed; // zelená dolů
      } else {
        setUpColorOrange(false);
        upSecondsRemaining = upRemainingTime; // červená dolů
        setUpColor("red");
      }

      const downMinutes = Math.floor(downSecondsRemaining / 60)
        .toString()
        .padStart(2, "0");
      const downSeconds = (downSecondsRemaining % 60)
        .toString()
        .padStart(2, "0");
      const upMinutes = Math.floor(upSecondsRemaining / 60)
        .toString()
        .padStart(2, "0");
      const upSeconds = (upSecondsRemaining % 60).toString().padStart(2, "0");

      setDownTime(`${downMinutes}:${downSeconds}`);
      setUpTime(`${upMinutes}:${upSeconds}`);
    };

    const interval = setInterval(updateTrafficLights, 1000);
    updateTrafficLights();

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <div>
        <Tabs defaultActiveKey="down" id="traffic-light-tabs">
          <Tab eventKey="down" title={t("down")}>
            <div className="d-flex align-items-center">
              <div>
                <div className="traffic-light">
                  <div
                    className={`light ${
                      downColor === "red" ? "red on" : "red off"
                    }`}
                  >
                    <div />
                  </div>
                  <div
                    className={`light ${
                      downColorOrange ? "yellow on" : "yellow off"
                    }`}
                  >
                    <div />
                  </div>
                  <div
                    className={`light ${
                      downColor === "green" ? "green on" : "green off"
                    }`}
                  >
                    <div />
                  </div>
                </div>
              </div>
              <div className="ml-4 display-1">
                <h2>{t("traffic_lights_down")}</h2>
                <p>{downTime}</p>
              </div>
            </div>
          </Tab>
          <Tab eventKey="up" title={t("up")}>
            <div className="d-flex align-items-center">
              <div>
                <div className="traffic-light">
                  <div
                    className={`light ${
                      upColor === "red" ? "red on" : "red off"
                    }`}
                  >
                    <div />
                  </div>
                  <div
                    className={`light ${
                      upColorOrange ? "yellow on" : "yellow off"
                    }`}
                  >
                    <div />
                  </div>
                  <div
                    className={`light ${
                      upColor === "green" ? "green on" : "green off"
                    }`}
                  >
                    <div />
                  </div>
                </div>
              </div>
              <div className="ml-4 display-1">
                <h2>{t("traffic_lights_up")}</h2>
                <p>{upTime}</p>
              </div>
            </div>
          </Tab>
          <Tab eventKey="about" title="Info">
            <div className="about">
              {t("about_content")}
              <p> &copy; 2023 Martin Tomašík</p>
            </div>
          </Tab>
        </Tabs>
      </div>
    </I18nextProvider>
  );
};

export default App;
