// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const languageDetectorOptions = {
  order: [
    "navigator",
    "querystring",
    "cookie",
    "localStorage",
    "htmlTag",
    "path",
    "subdomain",
  ],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          up: "Up",
          down: "Down",
          traffic_lights_down: "Trafficlights Down",
          traffic_lights_up: "Trafficlights Up",
          about: "About",
          about_content:
            "This application displays the states of traffic lights and the intervals between the cemetery (upward direction) and the pilgrimage site (downward direction) on Mount Živčáková, on the approach road from the village of Korňa. These values are derived from the cycle length of these traffic lights and do not represent the current state, but should remain valid until changes occur at the traffic lights.",
        },
      },
      cs: {
        translation: {
          up: "Nahoru",
          down: "Dolů",
          traffic_lights_down: "Semafor dolů",
          traffic_lights_up: "Semafor nahoru",
          about: "Info",
          about_content:
            "Tato aplikace zobrazuje stavy semaforů a intervaly mezi hřbitovem (směrem nahoru) a poutním místem (směrem dolů) na hoře Živčáková v příjezdové cestě z obce Korňa. Tyto hodnoty jsou odvozeny z délky cyklu těchto semaforů a nepředstavují aktuální stav, ale měly by být platné, dokud nedojde ke změnám na semaforech.",
        },
      },
      sk: {
        translation: {
          up: "Do hora",
          down: "Do dola",
          traffic_lights_down: "Semafor do dola",
          traffic_lights_up: "Semafor do hora",
          about: "Info",
          about_content:
            "Táto aplikácia zobrazuje stavy semaforov a intervaly medzi cintorínom (smerom nahor) a pútnym miestom (smerom dolu) na hore Živčáková v prístupovej ceste z obce Korňa. Tieto hodnoty sú odvodené z dĺžky cyklu týchto semaforov a nezobrazujú aktuálny stav, ale mali by byť platné, pokiaľ nedôjde k zmenám na semaforoch.",
        },
      },
    },
    detection: languageDetectorOptions,
    // lng: "en", // Výchozí jazyk
    fallbackLng: "cs", // Jazyk, který se použije, pokud vybraný jazyk není dostupný
    interpolation: {
      escapeValue: false, // Nepovinné: pokud chcete použít reálné úniky HTML v textech
    },
  });

export default i18n;
